<template>
  <transition name="selection-fade" @after-leave="handleAfterLeave">
    <div class="selection-content" v-show="visible">
      <header>
        <van-nav-bar
          :left-arrow="true"
          @click-left="onClickLeft"
          :title="!addVisible ? $t.order.selectAddress : addressTitle"
        ></van-nav-bar>
      </header>

      <transition name="selection-fade">
        <shipping-address
          :isSelection="true"
          :selectedId="selectedId"
          @selectedAddress="handleAddressSelected"
          @addAddress="handleAddAddress"
          @editAddress="handleEditAddress"
          @onClose="dismiss"
          class="address-list"
          v-if="!addVisible"
          ref="list"
        ></shipping-address>
        <edit-address
          :isSelection="true"
          :id="addressId"
          v-else
          class="add-address"
          @addressDidChanged="handleAddressDidChanged"
        ></edit-address>
      </transition>
    </div>
  </transition>
</template>

<script>
import ShippingAddress from "@view/user/shipping-address/ShippingAddress";
import EditAddress from "@view/user/edit-address/EditAddress";
export default {
  name: "ShippingAddressSelection",
  components: {
    ShippingAddress,
    EditAddress,
  },
  data() {
    return {
      selectedId: null,
      addressId: null,
      addressTitle: "",
      visible: false,
      addVisible: false,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
    this.selectedId = this.id;
    this.addressTitle = this.$t.meta.addAddress;
  },
  destroyed() {
    document.body.style.overflow = null;
  },
  methods: {
    onClickLeft() {
      this.dismiss();
    },
    handleAfterLeave() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    handleAddressSelected(address) {
      this.onSelected(address);
      this.dismiss();
    },
    handleAddAddress() {
      this.addVisible = true;
      this.addressId = null;
      this.addressTitle = this.$t.meta.addAddress;
    },
    handleEditAddress(address) {
      this.addVisible = true;
      this.addressId = JSON.stringify(address.URId);
      this.addressTitle = this.$t.meta.editAddress;
    },
    handleAddressDidChanged() {
      this.addVisible = false;
      this.$nextTick((_) => {
        this.$refs.list.loadUsersReceiving();
      });
    },
    onSelected(address) {},
    show() {
      this.visible = true;
    },
    dismiss() {
      this.visible = false;
      if (typeof this.onClose === "function") {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped>
.selection-fade-enter,
.selection-fade-leave-active {
  transform: translateX(100%);
  opacity: 0;
}
.selection-content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f6f6f6;
  z-index: 999;
  transition: all 0.3s;
  overflow-y: hidden !important;
  padding-top: env(safe-area-inset-top);
}

.address-list,
.add-address {
  height: 100%;
  overflow: scroll;
}

.add-address {
  transition: all 0.3s;
  position: relative;
}

header {
  overflow-y: hidden !important;
  background: linear-gradient(left, #71d283, #01aaa3);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
  overflow-y: hidden !important;
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}
</style>
