<template>
  <div class="orderCoupon">
    <div
      class="listWrapper"
      v-if="
        couponList &&
        (couponList.AvailableCouponList || couponList.UnAvailableCouponList)
      "
    >
      <van-radio-group v-model="coupon" @change="handleRadioChange">
        <div class="listTitle">
          {{ $t.couponInterpret.canuseTitle
          }}<span class="count"
            >({{
              $t.couponInterpret.totalCount(
                couponList.AvailableCouponList.length
              )
            }})</span
          >
        </div>
        <ul class="listBox" v-if="couponList.AvailableCouponList.length > 0">
          <li
            class="item"
            v-for="(item, index) in couponList.AvailableCouponList"
            :key="index"
          >
            <van-row type="flex" align="center" class="infoBox">
              <van-col class="infoBoxLeft">
                <div class="amount">
                  <span class="symbol">¥</span>{{ item.DiscountAmount }}
                </div>
                <div class="amount-right">
                  <div class="condition" v-if="!item.IsNoThreshold">
                    {{ $t.couponInterpret.condition(item.UseCondition) }}
                  </div>
                  <div class="condition" v-else>
                    {{ $t.couponInterpret.noCondition }}
                  </div>
                  <div class="date">
                    {{ $t.couponInterpret.endDate }} : {{ item.MaturityTime }}
                  </div>
                </div>
              </van-col>
              <van-col class="infoBoxRigth">
                <van-radio
                  :name="item"
                  checked-color="#01aaa3"
                  @click="handleRadioClick"
                ></van-radio>
              </van-col>
            </van-row>
          </li>
        </ul>
        <div class="listTitle">
          {{ $t.couponInterpret.notuseTitle
          }}<span class="count"
            >({{
              $t.couponInterpret.totalCount(
                couponList.UnAvailableCouponList.length
              )
            }})</span
          >
        </div>
        <ul class="listBox" v-if="couponList.UnAvailableCouponList.length > 0">
          <li
            class="item"
            v-for="(item, index) in couponList.UnAvailableCouponList"
            :key="index"
          >
            <van-row type="flex" align="center" class="usedBox">
              <van-col class="usedBoxLeft">
                <div class="amount">
                  <span class="symbol">¥</span>{{ item.DiscountAmount }}
                </div>
                <div class="amount-right">
                  <div class="condition" v-if="!item.IsNoThreshold">
                    {{ $t.couponInterpret.condition(item.UseCondition) }}
                  </div>
                  <div class="condition" v-else>
                    {{ $t.couponInterpret.noCondition }}
                  </div>
                  <div class="date">
                    {{ $t.couponInterpret.endDate }} : {{ item.MaturityTime }}
                  </div>
                  <div class="reason">{{ $t.couponInterpret.reason }}</div>
                </div>
              </van-col>
              <van-col class="usedBoxRigth">
                <van-radio :disabled="true" checked-color="#01aaa3"></van-radio>
              </van-col>
            </van-row>
          </li>
        </ul>
      </van-radio-group>
    </div>
    <placeholder v-else class="empty"></placeholder>
    <div class="tool-bar">
      <div class="right">
        <div class="price" v-if="coupon">
          <div class="title">{{ $t.order.coupon }}:</div>
          <div class="content">
            <span class="symbol">$</span>
            <span class="amount">{{ coupon.DiscountAmount }}</span>
          </div>
        </div>
        <div class="price" v-else>{{ $t.couponInterpret.notuseCoupon }}</div>
        <div class="btn-pay" @click="actionDidTap">
          <span>{{ $t.confirm }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Placeholder from "@/components/common/Placeholder";
import Vue from "vue";
import { Row, Col, Button, Radio, RadioGroup } from "vant";
Vue.use(Row).use(Col).use(Button).use(Radio).use(RadioGroup);

export default {
  name: "OrderCoupon",
  props: ["couponList"],
  components: {
    Placeholder,
  },
  data() {
    return {
      coupon: null,
      isChange: false,
    };
  },
  mounted() {
    if (this.couponList && this.couponList.AvailableCouponList) {
      for (var coupon of this.couponList.AvailableCouponList) {
        if (coupon.IsChecked) {
          this.coupon = coupon;
          break;
        }
      }
    }
  },
  methods: {
    handleRadioChange() {
      this.isChange = true;
    },
    handleRadioClick() {
      if (!this.isChange) {
        this.coupon = null;
      }
      this.isChange = false;
    },
    actionDidTap() {
      this.$emit("actionDidTap", this.coupon);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/var";
@import "~assets/scss/publicStyle";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.orderCoupon {
  width: 100%;
  height: 100%;
  overflow: scroll;
  .tool-bar {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: calc(0px + env(safe-area-inset-bottom));
    background-color: white;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    border-top: 1px solid #e5e5e5;
    .right {
      display: flex;
      align-items: center;

      .price {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-size: 14px;
        .title {
          color: black;
          margin-left: 12px;
          margin-right: 10px;
        }

        .content {
          color: $-color-red;
          font-weight: bold;
          .symbol {
            font-size: 12px;
            margin-right: 5px;
          }
          .amount {
            font-size: 16px;
          }
        }
      }

      .btn-pay {
        width: 90px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        background-color: $-color-primary;
        margin-left: 12px;
      }
    }
  }
  .empty {
    margin-top: 30%;
  }
  .listWrapper {
    margin-bottom: 30vh;
    .listTitle {
      margin: 15px 15px 0 15px;
      font-size: 14px;
      .count {
        margin-left: 10px;
        font-size: 12px;
        color: #999;
      }
    }
    .listBox {
      margin: 0% 3%;
      .item {
        padding: 15px;
        border-radius: 10px;
        background-color: #ffffff;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin-top: 15px;
        line-height: 50px;
        .infoBox {
          .infoBoxLeft {
            width: 77%;
            padding-right: 2.5%;
            margin-right: 2.5%;
            text-align: left;
            border-right: 2px dotted #01aaa3;
            .amount {
              color: #01aaa3;
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              width: 30%;
              display: inline-block;
              vertical-align: middle;
              .symbol {
                font-size: 14px;
                margin-right: 5px;
              }
            }
            .amount-right {
              width: 70%;
              display: inline-block;
              vertical-align: middle;
              .condition {
                color: #999;
                font-size: 12px;
                line-height: 2;
              }
              .date {
                color: #999;
                font-size: 12px;
                line-height: 2;
              }
            }
          }
          .infoBoxRigth {
            width: 20%;
            text-align: center;
            position: relative;
            height: 52px;
            .doneBtn {
              font-weight: bold;
              border: none;
              padding: 0;
              vertical-align: middle;
              .van-button__text {
                font-size: 14px !important;
              }
            }
            .van-radio {
              position: absolute;
              left: 40%;
              top: 30%;
            }
          }
        }
        .usedBox {
          .usedBoxLeft {
            width: 77%;
            padding-right: 2.5%;
            margin-right: 2.5%;
            text-align: left;
            border-right: 2px dotted #999;
            .amount {
              color: #999;
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              width: 30%;
              display: inline-block;
              vertical-align: middle;
              .symbol {
                font-size: 14px;
                margin-right: 5px;
              }
            }
            .amount-right {
              width: 70%;
              display: inline-block;
              vertical-align: middle;
              .condition {
                color: #999;
                font-size: 12px;
                line-height: 2;
              }
              .date {
                color: #999;
                font-size: 12px;
                line-height: 2;
              }
              .reason {
                color: $-color-red;
                font-size: 10px;
                line-height: 2;
              }
            }
          }
          .usedBoxRigth {
            width: 20%;
            text-align: center;
            position: relative;
            height: 52px;
            .van-radio {
              position: absolute;
              left: 40%;
              top: 30%;
            }
          }
        }
      }
    }
  }
}
</style>
