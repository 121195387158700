<template>
  <div class="order">
    <div class="list">
      <div class="section address" @click="actionAddressSelection">
        <img src="~assets/img/order/icon_order_location.png" class="icon" />
        <div class="info" v-if="address">
          <p class="title">
            {{ address.ReceivingName }}
            <span>{{ address.PhoneNumber }}</span>
          </p>
          <p class="detail">{{ address.ReceivingAddress }}</p>
        </div>
        <p class="not-selected" v-else>{{ $t.order.selectAddress }}</p>
        <img src="~assets/img/order/icon_order_right.png" class="accessory" />
      </div>
      <div class="section express">
        <div class="content" @click="actionSelectFreight">
          <div class="info">
            <p class="title">
              {{
                $t.order.shippingMethod +
                (freight ? freight.FreightModelName : $t.unselected)
              }}
            </p>
            <p class="detail">
              <span>{{
                $t.order.firstWeight(freight ? freight.FirstWeight : 0)
              }}</span>
              ${{ (freight ? freight.FirstWeightPrice : 0) | formatPrice }}
              <span>{{
                $t.order.secondWeight(freight ? freight.ContinuousWeight : 0)
              }}</span>
              ${{ (freight ? freight.ContinuousWeightPrice : 0) | formatPrice }}
            </p>
          </div>
          <img src="~assets/img/order/icon_order_right.png" class="icon" />
        </div>
        <div class="sum">
          <p class="title">{{ $t.order.freightAmount }}</p>
          <p class="detail">
            ${{ (freight ? freight.Freight : 0) | formatPrice }}
          </p>
        </div>
      </div>
      <div class="section coupon">
        <div class="content" @click="actionCouponSelection">
          <div class="info">
            <p class="title">
              <span class="label">
                {{ $t.couponInterpret.selectedCoupon }}:
              </span>
              <span v-if="selectedDiscount && selectedDiscount.DiscountAmount">
                <div class="amount">
                  <span class="symbol">¥</span
                  >{{ selectedDiscount.DiscountAmount }}
                </div>
                <span class="condition" v-if="!selectedDiscount.IsNoThreshold">
                  (
                  {{
                    $t.couponInterpret.condition(selectedDiscount.UseCondition)
                  }}
                  )
                </span>
                <span class="condition" v-else>
                  ( {{ $t.couponInterpret.noCondition }} )
                </span>
              </span>
              <span v-else>
                {{ $t.unselected }}
              </span>
            </p>
          </div>
          <img src="~assets/img/order/icon_order_right.png" class="icon" />
        </div>
        <div class="sum">
          <p class="title">{{ $t.couponInterpret.discountTotal }}</p>
          <p class="detail">
            ${{
              (selectedDiscount ? selectedDiscount.DiscountAmount : 0)
                | formatPrice
            }}
          </p>
        </div>
      </div>
      <div class="section products" v-for="shop in content" :key="shop.shopID">
        <p class="shop-name">{{ shop.shopName }}</p>
        <div
          class="product"
          v-for="(product, index) in shop.products"
          :key="index"
        >
          <van-image class="photo" :src="productFirstImg(product.ProductPhoto)">
            <template v-slot:error>
              <img class="ImgIcon" src="~assets/img/other/empty.gif" />
            </template>
          </van-image>

          <div class="content">
            <div class="title-box">
              <div class="title">
                <div class="discount" v-if="product.IsCouponProduct">
                  {{ $t.discount }}
                </div>
                <div class="name">{{ product.ProductName }}</div>
              </div>
              <p class="money" v-if="product.ProductDiscountedAmount">
                <span class="oldPrice"
                  >${{ product.NowMoney | formatPrice }}</span
                >
                <span class="newPrice"
                  >${{ product.ProductDiscountedAmount | formatPrice }}</span
                >
              </p>
              <p class="money" v-else>${{ product.NowMoney | formatPrice }}</p>
            </div>
            <div class="detail-box">
              <p class="detail">
                {{ product.ProductModelStr | productModelStrFilter }}
              </p>
              <p class="count">x{{ product.Number }}</p>
            </div>
          </div>
        </div>
        <div class="sum">
          <p class="count">{{ $t.order.total(shop.total) }}</p>
          <p class="money">
            $
            <span>{{ shop | sumShopPrice | formatPrice }}</span>
          </p>
        </div>
      </div>
      <div class="section details">
        <div class="content">
          <div class="title">{{ $t.order.priceDetail }}</div>
          <div class="info">
            <div class="left">
              <span>{{ $t.order.productTotal }}</span>
              <span class="count">{{ $t.order.total(productSum) }}</span>
            </div>
            <div class="right">
              <span class="symbol">$</span>
              <span class="amount">{{ productTotalAmount }}</span>
            </div>
          </div>
          <div class="info">
            <div class="left">{{ $t.order.freightAmount }}</div>
            <div class="right">
              <span class="symbol">$</span>
              <span class="amount" v-if="freight">{{ freight.Freight }}</span>
              <span class="amount" v-else>0.00</span>
            </div>
          </div>
          <div class="info">
            <div class="left">{{ $t.order.coupon }}</div>
            <div class="right">
              <span v-if="selectedDiscount" class="discount">
                <span class="symbol">- $</span>
                <span>{{
                  (selectedDiscount ? selectedDiscount.DiscountAmount : 0)
                    | formatPrice
                }}</span>
              </span>
              <span v-else>
                <span class="symbol">$</span><span class="amount">0.00</span>
              </span>
            </div>
          </div>
          <div class="info">
            <div class="left">{{ $t.order.allTotal }}</div>
            <div class="right">
              <span class="symbol">$</span>
              <span class="amount">{{ orderTotalAmount }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tool-bar">
      <div class="right">
        <div class="price">
          <p class="detail">{{ $t.order.shippingIncluded }}</p>
          <p class="title">{{ $t.order.allTotal }}:</p>
          <p class="content">
            $
            <span>{{ orderTotalAmount }}</span>
          </p>
        </div>
        <div class="btn-pay" @click="actionSubmit">
          <span>{{ $t.order.submitOrder }}</span>
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="handleFreightCancel"
        @confirm="handleFreightDidSelected"
      />
    </van-popup>
  </div>
</template>

<script>
import { isAPP } from "@/plugins/environment/type.js"; // 判断是否在app环境
import Selection from "@view/user/shipping-address-selection/index.js";
import Schema from "async-validator";
import { Picker, Popup, Image } from "vant";
import CouponSelection from "./index.js";

import Vue from "vue";
Vue.use(Popup).use(Picker).use(Image);

export default {
  name: "Order",
  data() {
    return {
      content: [],
      productSum: 0,
      orderTotalAmount: null,
      productTotalAmount: null,
      address: null,
      freight: null,
      selectedFreight: null,
      freights: [],
      columns: [],
      showPicker: false,
      isFirstRequest: 1,
      selectedDiscount: null,
      couponId: null,
      couponList: [],
      companyIcon: require("@/assets/img/userManage/img_logo@2x.png"),
    };
  },
  computed: {
    queryList() {
      return JSON.parse(this.$route.query.list) || [];
    },
  },
  filters: {
    formatPrice(val) {
      var str = val.toFixed(2) + "";
      var intSum = str
        .substring(0, str.indexOf("."))
        .replace(/\B(?=(?:\d{3})+$)/g, ","); // 取到整数部分
      var dot = str.substring(str.length, str.indexOf(".")); // 取到小数部分搜索
      var ret = intSum + dot;
      return ret;
    },
    sumShopPrice(shop) {
      return shop.products.reduce((origin, product) => {
        return (
          origin +
          (product.ProductDiscountedAmount
            ? product.ProductDiscountedAmount
            : product.NowMoney) *
            product.Number
        );
      }, 0);
    },
    // 规则过滤器
    productModelStrFilter(value) {
      return value.replace(/\|/, " ");
    },
  },
  created() {
    this.request();
  },
  methods: {
    freightImages() {
      this.columns = [];
      this.freights.forEach((item) => {
        var icon = "";
        if (item.CompanyIcon) {
          icon = this.imgUrlVtrack(item.CompanyIcon);
        } else {
          icon = this.companyIcon;
        }
        this.columns.push({
          text:
            `<img class="columns-img" src='${icon}'>` +
            `<span class="columns-name">` +
            item.FreightModelName +
            `</span>`,
        });
      });
    },
    request(addressID, freightID, couponID) {
      this.$commonMethod.showLoading();
      const param = {
        IsFirstRequest: this.isFirstRequest,
        ProductList: this.queryList,
      };
      addressID !== undefined && (param.URId = addressID);
      freightID !== undefined && (param.FMId = freightID);
      couponID !== undefined && (param.CCId = couponID);
      this.$api.order
        .loadOrderPreview(param)
        .then((res) => {
          this.content = this.combineData(res.data.OrderProductListPreview);
          //总价
          this.orderTotalAmount = res.data.OrderTotalAmount;
          this.productTotalAmount = res.data.ProductTotalAmount;
          //运费
          this.freight = res.data.OrderFreightModelPreview;
          this.freights = res.data.UserFreightModelList;
          this.selectedFreight = res.data.DefultUserFreightModel;
          //地址
          this.address = res.data.DefultUsersReceiving;
          //优惠券
          this.selectedDiscount = res.data.DefultCouponModel;
          if (res.data.DefultCouponModel) {
            this.couponId = res.data.DefultCouponModel.CCId;
          }
          this.couponList = res.data.UserCoupon;
          this.$commonMethod.hideLoading();
          //自动选择优惠券
          if (this.isFirstRequest) this.isFirstRequest = 0;
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "error",
            titleMsg: err.message,
          });
        });
    },
    actionSelectFreight() {
      if (this.address) {
        if (this.freights.length > 0) {
          this.showPicker = true;
          this.freightImages();
        } else {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: this.$t.order.placeholderSelectEmpty,
            status: 400,
          });
        }
      } else {
        this.$commonMethod.showToast({
          type: "error",
          titleMsg: this.$t.order.placeholderSelectAddressFirst,
        });
      }
    },
    actionAddressSelection() {
      if (this.address && this.address.URId) {
        const selection = Selection({ id: this.address.URId });
        selection.onSelected = (address) => {
          this.request(
            address.URId | undefined,
            this.selectedFreight ? this.selectedFreight.FMId : undefined,
            this.couponId
          );
        };
        selection.show();
      } else {
        this.getAddress();
      }
    },
    actionSubmit() {
      const data = {
        address: this.address,
        productList: this.queryList,
        selectedFreight: this.selectedFreight,
        freight: this.freight,
        CCId: this.couponId,
      };
      const rule = {
        productList: { required: true, message: this.$t.errorParamGoBack },
        address: {
          required: true,
          message: this.$t.order.placeholderSelectAddress,
        },
        selectedFreight: {
          required: true,
          message: this.$t.order.placeholderSelectFreight,
        },
        freight: {
          required: true,
          message: this.$t.order.placeholderSelectFreight,
        },
      };
      const validator = new Schema(rule);
      validator
        .validate(data)
        .then((_) => {
          this.$commonMethod.showLoading();
          const param = {
            ProductList: data.productList,
            URId: data.address.URId,
            PostalCode: data.address.PostalCode,
            FMId: data.selectedFreight.FMId,
            Freight: data.freight.Freight,
            CCId: data.CCId,
          };
          if (!isAPP()) {
            param.OS = "web";
          } else {
            param.OS = "app";
          }
          return this.$api.order.orderPreviewByCroPay(param);
        })
        .then((res) => {
          this.$commonMethod.hideLoading();
          const data = res.data;
          this.getDetail(data);
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          } else {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          }
        });
    },
    handleFreightDidSelected(value, index) {
      this.request(this.address.URId, this.freights[index].FMId, this.couponId);
      this.showPicker = false;
    },
    handleFreightCancel() {
      this.showPicker = false;
    },
    combineData(data) {
      let content = [];
      for (const product of data) {
        product.isSelected = false;
        let shop = content.find((v) => {
          return v.shopID === product.ShopId;
        });
        if (shop) {
          shop.products.push(product);
          shop.total = 0;
        } else {
          content.push({
            shopName: product.ShopName,
            shopID: product.ShopId,
            products: [product],
            total: 0,
          });
        }
      }
      //计算件数
      for (const parent of content) {
        if (parent.total == 0) {
          for (const item of parent.products) {
            parent.total = parent.total + item.Number;
          }
        }
      }
      this.productSum = content.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        0
      );
      return content;
    },
    productFirstImg(val) {
      return this.$api.formatImageUrl(val.split("|")[0]);
    },
    imgUrlVtrack(url) {
      return this.$api.formatVtrackUrl(url);
    },
    // 跳转订单详情
    getDetail(id) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerReplace, {
        isParams: true,
        path: `orderDetails/${id}`,
        name: "OrderDetails",
        query: [
          {
            name: "ShowPayment",
            value: true,
          },
        ],
      });
    },
    getAddress() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "user/shippingAddress",
        name: "shippingAddress",
        query: [],
      });
    },
    actionCouponSelection() {
      const couponSelection = CouponSelection({
        couponList: this.couponList,
      });
      couponSelection.onSelected = (data) => {
        if (data) {
          this.couponId = data.CCId;
        } else {
          this.couponId = null;
        }
        this.request(
          this.address.URId,
          this.selectedFreight ? this.selectedFreight.FMId : undefined,
          this.couponId
        );
      };
      couponSelection.show();
    },
  },
  beforeDestroy() {
    this.$commonMethod.hideLoading();
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
@import "~assets/scss/publicStyle";
$tool-bar-height: 55px;
.order {
  padding: 15px 15px (15px + $tool-bar-height);

  .list > {
    .section {
      background-color: white;
      border-radius: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .address {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 100px;

      .icon {
        width: 26px;
        height: 26px;
        flex-shrink: 0;
      }
      .info {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 20px;

        .title {
          font-size: 15px;
          color: black;
          margin: 0;

          span {
            margin-left: 8px;
            font-size: 13px;
            color: $-color-light-gray;
            font-weight: normal;
          }
        }
        .detail {
          margin: 5px 0 0;
          font-size: 13px;
          color: black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          white-space: normal;
        }
      }
      .not-selected {
        color: black;
        margin: 0 0 0 20px;
        font-size: 13px;
        flex-grow: 1;
      }
      .accessory {
        width: 12px;
        height: 12px;
        margin-left: 30px;
        flex-shrink: 0;
      }
    }
    .express {
      .content {
        padding: 0 15px;
        height: 80px;
        border-bottom: 0.5px solid $-color-background;
        display: flex;
        align-items: center;

        .info {
          flex-grow: 1;

          .title {
            margin: 0;
            font-size: 15px;
          }
          .detail {
            margin: 12px 0 0;
            font-size: 13px;
            color: black;

            span {
              color: $-color-light-gray;
              margin-left: 10px;

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .icon {
          width: 12px;
          height: 12px;
          margin-left: 30px;
          flex-shrink: 0;
        }
      }
      .sum {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;

        .title {
          font-size: 13px;
          color: $-color-light-gray;
        }
        .detail {
          font-size: 14px;
          color: $-color-red;
        }
      }
    }
    .coupon {
      .content {
        padding: 0 15px;
        height: 60px;
        line-height: 60px;
        border-bottom: 0.5px solid $-color-background;
        display: flex;
        align-items: center;

        .info {
          flex-grow: 1;

          .title {
            margin: 0;
            font-size: 15px;
            .label {
              margin-right: 5px;
            }
            .amount {
              color: #01aaa3;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              display: inline-block;
              vertical-align: middle;
              margin: 0px 5px;
              .symbol {
                font-size: 12px;
                margin-right: 5px;
              }
            }
            .condition {
              color: #a7a7a7;
              font-size: 12px;
              line-height: 2;
            }
          }
        }
        .icon {
          width: 12px;
          height: 12px;
          margin-left: 30px;
          flex-shrink: 0;
        }
      }
      .sum {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;

        .title {
          font-size: 13px;
          color: $-color-light-gray;
        }
        .detail {
          font-size: 14px;
          color: $-color-red;
        }
      }
    }
    .products {
      padding-top: 20px;

      .shop-name {
        font-size: 13px;
        font-weight: bold;
        color: black;
        padding-left: 15px;
        margin: 0;
      }
      .product {
        padding: 16px 15px 20px;
        border-bottom: 0.5px solid $-color-background;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        word-break: break-word;
        .photo {
          width: 56px;
          height: 56px;
          object-fit: cover;
          .ImgIcon {
            height: 56px;
            width: 56px;
            object-fit: cover;
          }
          ::v-deep .van-image__img {
            height: 56px;
            width: 56px;
            object-fit: cover;
          }
          ::v-deep .van-image__error {
            height: 56px;
            width: 56px;
            object-fit: cover;
          }
        }
        .content {
          margin-left: 13px;
          flex-grow: 1;

          p {
            margin: 0;
          }

          .title-box,
          .detail-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .title-box {
            .title {
              flex-grow: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
              white-space: normal;
              .discount {
                vertical-align: middle;
                display: inline-block;
                background: red;
                color: white;
                padding: 0px 2px;
                border-radius: 2px;
                font-size: 12px;
                margin-right: 5px;
              }
              .name {
                vertical-align: middle;
                display: inline-block;
                font-size: 14px;
                color: black;
              }
            }
            .money {
              font-size: 12px;
              flex-shrink: 0;
              margin-left: 20px;
              .newPrice {
                color: $-color-red;
                font-size: 14px;
              }
              .oldPrice {
                margin-right: 5px;
                font-size: 12px;
                text-decoration: line-through;
                color: $-color-light-gray;
              }
            }
          }
          .detail-box {
            margin-top: 12px;
            .detail {
              width: 80%;
              font-size: 13px;
              color: $-color-light-gray;
            }
            .count {
              font-size: 13px;
              color: $-color-light-gray;
            }
          }
        }
      }
      .sum {
        padding: 0 15px;
        height: 50px;
        display: flex;
        justify-content: space-between;

        .count {
          font-size: 13px;
          color: $-color-light-gray;
        }

        .money {
          color: $-color-red;
          font-size: 12px;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .details {
      margin-bottom: 10px;
      .content {
        padding: 0px 15px 15px 15px;
        .title {
          font-weight: bold;
          font-size: 15px;
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 0.5px solid $-color-background;
        }
        .info {
          padding-top: 15px;
          .left {
            font-size: 14px;
            width: 50%;
            display: inline-block;
            .count {
              margin-left: 10px;
              font-size: 12px;
              color: $-color-light-gray;
            }
          }
          .right {
            width: 50%;
            display: inline-block;
            text-align: right;
            .symbol {
              font-size: 12px;
              margin-right: 5px;
            }
            .amount {
              font-size: 14px !important;
            }
            .discount {
              font-size: 14px !important;
              color: $-color-red;
            }
          }
        }
      }
    }
  }

  .tool-bar {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: white;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    border-top: 1px solid #e5e5e5;
    .right {
      display: flex;
      align-items: center;

      .price {
        display: flex;
        align-items: center;
        margin-right: 8px;

        p {
          margin: 0;
        }

        .detail {
          font-size: 12px;
          color: $-color-light-gray;
        }

        .title {
          font-size: 14px;
          color: black;
          margin-left: 12px;
          margin-right: 5px;
        }

        .content {
          font-size: 12px;
          color: $-color-red;
          font-weight: bold;

          span {
            font-size: 18px;
          }
        }
      }

      .btn-pay {
        width: 90px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        background-color: $-color-primary;
        margin-left: 12px;
      }
    }
  }
}
.picker {
  position: fixed;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
}

::v-deep .van-ellipsis {
  display: flex;
  align-items: center;
  .columns-img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
    object-fit: contain;
  }
  .columns-name {
    vertical-align: middle;
    line-height: 25px;
  }
}
</style>
